<template>
	<div class="">
		<Menu
			:is-show-mobile="isMenuOpen"
			@backdrop-clicked="closeMobileMenu"
			@item-clicked="closeMobileMenu"
		/>
		<div class="outer-container">
			<Navbar :class="{'d-none': !isNavbarShow, 'd-flex': isNavbarShow, 'd-md-flex': true }" @hamburger-clicked="openMobileMenu" />
			<router-view
				@show-mobile-navbar="showMobileNavbar"
				@hide-mobile-navbar="hideMobileNavbar"
			/>
		</div>
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
export default {
	components: {
		Navbar,
		Menu
	},
	data() {
		return {
			isNavbarShow: true,
			isMenuOpen: false,
		}
	},
	methods: {
		openMobileMenu() {
			this.isMenuOpen = true;
		},
		closeMobileMenu() {
			this.isMenuOpen = false;
		},
		showMobileNavbar() {
			this.isNavbarShow = true;
		},
		hideMobileNavbar() {
			this.isNavbarShow = false;
		},
	},
}
</script>

<style>
	.outer-container {
		margin-left: 0;
	}

	@media (min-width: 768px) {
		.outer-container {
			margin-left: 80px;
		}
	}
</style>