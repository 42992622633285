<template>
    <div
        @click="$emit('backdrop-clicked')"
        class="position-fixed backdrop"
        :style="`top: 0; bottom: 0; left: 0; right: ${backdropRight}; bottom: 0; z-index: 1030; background-color: rgba(39, 42, 51, .3);`"
    >
        <div
            class="menu position-fixed bg-white border-right-secondary py-1"
            :style="`top: 0; left: ${left}; bottom: 0; z-index: 1031; width: ${menuWidth}; transition: left .2s ease-in;`"
        >
            <div class="d-flex flex-column align-items-center mb-5" style="gap: .25rem" @click="redirect()">
                <img src="/assets/img/logo-diri.png" width="56" class="img-fluid" alt="">
                <span class="f-10 text-secondary">Back Office</span>
            </div>
            <ul class="m-0 p-0 d-flex flex-column align-items-center" style="list-style-type: none; gap: 1rem;">
                <li class="" v-for="(data, idx) in menus" :key="idx">
                    <router-link :to="data.children && data.children.length > 0 ? '' : data.link" @click="$emit('item-clicked')">
                        <button class="btn p-0 rounded" :class="isActive(data)" :title="data.name">
                            <img :src="data.icon" alt="" style="width: 40px; margin: 5px;">
                        </button>
                        <!-- <span class="menu-title align-self-center font-400">{{data.name}}</span> -->
                        <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                    </router-link>
                    <ul class="menu-content">
                        <li v-for="(dt, i) in data.children" :key="i">
                            <router-link :to="dt.link">
                                <i class="ml-2 fa fa-circle"></i>
                                <span class="menu-item">{{dt.name}}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

const WIDTH = 80;

export default {
    props: ['isShowMobile'],
    data() {
        return {
            menuWidth: `${WIDTH}px`,
            left: `-${WIDTH}px`,
            backdropRight: '100%',
            menus: []
        }
    },
    watch: { 
        isShowMobile: function(isShow) {
            this.left = isShow ? 0 : `-${WIDTH}px`;
            this.backdropRight = isShow ? 0 : '100%';
        }
    },
    methods: {
        isActive(data){
            let _ = this
            if (data.children && data.children.length > 0) {
                let isThere = data.children.filter(x => x.link == _.$route.fullPath);
                if (isThere.length > 0) {
                    return 'btn-primary';
                }
            } else {
                if (data.link == _.$route.fullPath || _.$route.fullPath.indexOf(data.link) != -1){
                    return 'btn-primary';
                }
            }
        },
        getMenu() {
            let _ = this
            let data = JSON.parse(localStorage.getItem('account'))
            _.menus = data.admin.role.menus.filter( menu => {
                const show = [
                    '/konsultasi-dan-resep',
                    '/daftar-pelanggan',
                    '/daftar-transaksi',
                    '/apotek-dan-pengiriman',
                ];

                return show.includes(menu.link)
            })
        },
        redirect() {
            this.$router.push(this.menus[0].link)
        }
    },
    mounted() {
        let _ = this

        _.getMenu()

        _.$root.$on('changeMenuCounter', (data) => {
            _.menus[data.id_menu].counter -= data.counter
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterToZero', (data) => {
            _.menus[data.id_menu].counter = 0
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterTo', (data) => {
            _.menus[data.id_menu].counter = data.counter
            _.$forceUpdate();
        })
    },
}
</script>

<style>
	@media (min-width: 768px) {
		.backdrop {
			right: 100% !important;
		}

        .menu {
            left: 0 !important;
        }
	}
</style>