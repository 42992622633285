<template>
    <header class="sticky-top align-items-center justify-content-between py-1 px-2 bg-white border-bottom-secondary">
        <div class="d-flex align-items-center" style="gap: 1rem;">
            <img v-if="!$route.meta.backButton" src="/assets/img/icon/hamburger-menu-icon.svg" class="d-block d-md-none cursor-pointer" @click="$emit('hamburger-clicked')" alt="" />
            <img v-if="$route.meta.backButton" width="28" height="28" src="/assets/img/icon/arrow-left-icon.svg" class="d-block cursor-pointer" @click="$router.go(-1)" alt="" />
            <p class="f-20 font-500 mb-0">{{ $route.meta.title }} <span class="text-blue">{{ additional_info }}</span></p>
        </div>
        <div>
            <a class="d-flex align-items-center" href="#" data-toggle="dropdown">
                <img class="round" style="object-fit: cover" :src="account.admin.avatar ? account.admin.avatar:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                <div class="user-nav d-sm-flex d-none ml-1 text-right">
                    <span class="font-600 f-14 text-black">{{account.admin.fullName}} <i class="fa fa-chevron-down"></i></span>
                </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <section class="d-lg-none">
                    <div class="col" style="margin-top: 5px;">
                        <p class="text-primary font-600 f-16 mb-0">{{account.admin.fullName}}</p>
                        <p class="text-black f-12 mb-0">{{account.admin.role.name}}</p>
                    </div>
                    <div class="dropdown-divider"></div>
                </section>
                <router-link to="/user/edit_profile" tag="a" class="dropdown-item">
                    <i class="feather icon-user"></i> Ubah Profile
                </router-link>
                <router-link to="/user/change_password" tag="a" class="dropdown-item">
                    <i class="feather icon-lock"></i> Ubah Password
                </router-link>
                <div class="dropdown-divider"></div>
                <a href="javascript:;" @click="logout()" class="dropdown-item">
                    <i class="feather icon-power"></i> Keluar
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            account: JSON.parse(localStorage.getItem('account')),
            additional_info: ''
        }
    },
	methods: {
		fullscreen(){
            let elem = document.documentElement
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                this.isFullScreen = 1
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullScreen = 0
            }
        },
        logout() {
            let _ = this
            $(function(){                
                // $('body').removeClass('menu-collapsed')
                // $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
			window.location = '/login'
        }
	},
    mounted() {
        let _ = this
        _.$root.$on('changeProfile', (data) => {
            _.account = data
            localStorage.setItem('account', JSON.stringify(_.account))
        })

        _.$root.$on('addAdditionalInfo', (data) => {
            _.additional_info = data
        })
    },
}
</script>